import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import * as Yup from 'yup';
import { Formik, Field, Form, FieldArray } from 'formik';
import Box from '../Form/Box'
import TextFieldHalf from '../Form/TextFieldHalf'
import SelectFieldBare from '../Form/SelectFieldBare'


export default function Playcentre() {  

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { playcentreId } = useParams();
  let playcentreUrl = playcentreId ? `admin/playcentre/${playcentreId}/edit` : `admin/playcentre/add`;
  let navigate = useNavigate();
  const saveButtonText = playcentreId ? `Update Playcentre` : `Add a new Playcentre`;  
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const loadPc = () => {
      api
        .get(playcentreUrl)
        .then((response) => {
          setData(response.data);          
          setLoading(false);
        });
    };

    loadPc();
  }, []);  

  if (loading)
    return <Loading />; 
   

  return (
    <>
      <h1>Playcentre - {data.shortName !== '' ? `${data.shortName}` : 'new'}</h1>

      <Formik
        initialValues={{ data: data }}
        validationSchema={ Yup.object().shape({
            data: Yup.object().shape({
              shortName: Yup.string().required('Required'),
              fullName: Yup.string().required('Required'),
              url: Yup.string().required('Required')
            })          
        })}
        onSubmit={(values, { setSubmitting }) => {
          api.post(playcentreUrl, values.data)
            .then(() => navigate(`/admin/playcentres`))
            .catch((error) => {
              console.log(error); setErrorMessage(error.response.statusText);
          });
          setTimeout(() => {
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >{(props) =>
        <Form>
          <div className="accordion">
            <Box header="Playcentre details">
              <div className="row">
                  <TextFieldHalf title="Full Name (ex: Hammerbarn Playcentre)" objectPath="data.fullName" id="fullName" />                  
              </div>
              <div className="row">
                  <TextFieldHalf title="Short Name (ex: Hammerbarn)" objectPath="data.shortName" id="shortName" />
              </div>
              <div className="row">
                  <TextFieldHalf title="Unique playcentre url (ex: hammerbarn)" objectPath="data.url" id="url" />
              </div>
            </Box>                      
          </div>

          <div className="d-grid gap-2 col-6 mx-auto mt-5 mb-5 pb-5">
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <button className="btn btn-primary fw-bold" type="submit">{saveButtonText}</button>
          </div>
          <div className="pt-3"></div>
        </Form>
        }
      </Formik>

    </>
  );
}
