import { useParams } from 'react-router-dom';

export const useFullUrl = () => {
  const { pcId, termYear, termIndex } = useParams();
  return `/pc/${pcId}/term/${termYear}/${termIndex}`;
};

export const useShortUrl = () => {
  const { pcId } = useParams();
  return `/pc/${pcId}`;
};