import { useParams } from 'react-router-dom';

import AuthService from '../services/auth.service';
export default function Home() {
  const { termYear, termIndex, pcId } = useParams();

  return (
    <div>
      <h1>Hi, {AuthService.getLoggedInUser()?.firstName}</h1>
      <br />

      <h4>Welcome to the new roster system!</h4>
      <br />
      <p><i>For the best experience, we recommend using a laptop, as the system contains large tables that display better on a larger screen.</i></p>

      <br />

      <h5>Features at a Glance:</h5>
      <ul>
        <li>Easily manage your member list.</li>
        <li>Create and edit rosters.</li>
        <li>Download and share reports.</li>
      </ul>
      <br />
      <h5>Need Help?</h5>
      <p>For any questions or assistance, contact the developer at <a href="mailto:playcentre@exact-iot.com?subject=Playcentre Roster question">playcentre@exact-iot.com</a></p>
      <br />

      <hr />
      <h5>Getting Started:</h5>
      <br />

      <b>Step 1: Set up your members and kids</b>
      <ol>
        <li>Go to <span className="fw-semibold">"Members" -{'>'} "<a href={`/pc/${pcId}/settings/members/list`} className="text-decoration-none">Edit playcentre members</a>"</span> and enter the details for members and their kids.</li>
      </ol>

      <b>Step 2: Select a term</b>
      <ol>
        <li>Choose an active term from the dropdown at the top of the page.</li>
      </ol>

      <b>Step 3: Create your roster</b>
      <ol>
        <li>Go to <span className="fw-semibold">"Roster" -{'>'} "<a href={`/pc/${pcId}/term/${termYear}/${termIndex}/roster/edit`} className="text-decoration-none">Edit roster</a>"</span> and assign member days, ensuring you select attending kids.</li>
      </ol>

      <b>Step 4: View or print the roster</b>
      <ol>
        <li>Go to <span className="fw-semibold">"Roster" -{'>'} "<a href={`/pc/${pcId}/term/${termYear}/${termIndex}/roster`} className="text-decoration-none">View roster</a>"</span> to see the results.</li>
        <li>Use the <span className="fw-semibold">Print</span> button (top-right corner) or right-click and select "Print." You can also save it as a PDF from the print dialog.</li>
      </ol>     

      <br />
      <hr />
      <h5>Reports:</h5>
      <ul>
        <li>Access available reports under the <span className="fw-semibold">"Reports"</span> menu.</li>
        <li>Download them as Excel files for further use or to send to your Regional Administrator.</li>
      </ul>
     
      <br />

      <p><b>Tips for Printing Reports:</b></p>
      <ul>
        <li>If a report contains multiple sheets (e.g., 'Change of days of enrolment' or 'Day book'), ensure you select all sheets before printing or saving as PDF.</li>
        <li>To print a roster, use the Print button, then either print it directly or save it as a PDF.</li>
      </ul>
      <p></p>

      <br />
      <hr />
      <br />

      <p>Enjoy!</p>

      <br />
      <hr />
      <br />
      <br />
    </div>




  );
}
