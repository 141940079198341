import { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { useFullUrl } from '../../utils/basePlaycentreUrl';

export default function Terms() {
  const baseUrl = useFullUrl();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //const loadMembers = () => {
    //  api
    //    .get(`${baseUrl}/kids/days`)
    //    .then((response) => {
        
          setLoading(false);
    //    });
    //};
    //loadMembers();    

  }, [baseUrl]);


  if (loading)
    return <Loading />;

  return (
    <>
      <h1>Terms</h1>

    </>
  );
}

