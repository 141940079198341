import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import './Sidebar.module.scss'

const Sidebar = props => {

  return (
    <>

      <Nav className="col-md-12 d-none d-md-block bg-light sidebar"
        activeKey="/home"
        /*onSelect={selectedKey => alert(`selected ${selectedKey}`)}*/
      >
        <div className="sidebar-sticky"></div>
        <Nav.Item>
          <Link className="nav-link" to="/admin/users">Users</Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/admin/playcentres">Playcentres</Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/admin/terms">Terms</Link>          
        </Nav.Item>
        <Nav.Item>
        {/*<Nav.Link eventKey="disabled" disabled>*/}
        {/*  Disabled*/}
        {/*</Nav.Link>*/}
        </Nav.Item>
      </Nav>

    </>
  );
};
export default Sidebar
