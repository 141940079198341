import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import * as Icon from 'react-bootstrap-icons';

export default function Playcentres() {

  const [loading, setLoading] = useState(true);
  const [pcs, setPcs] = useState([]);
  useEffect(() => {
    const loadPcs = () => {
      api
        .get(`admin/playcentres`)
        .then((response) => {
          setPcs(response.data.playcentres);
          setLoading(false);
        });
    };

    loadPcs();
  }, []);


  if (loading)
    return <Loading />;

  return (
    <>
      <h1>Playcentre list</h1>
      <div className="text-end mb-3">
        <Link to="/admin/playcentre/add" className="btn btn-primary">Create a new Playcentre</Link>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Full&nbsp;name</th>
            <th scope="col">Short&nbsp;Name</th>
            <th scope="col">url</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {pcs.map((pc, index) => (
            <tr key={pc.uid}>
              <th scope="row">{index + 1}</th>
              <td>{pc.fullName}</td>
              <td>{pc.shortName}</td>
              <td>{pc.url}</td>
              <td><Link to={`/admin/playcentre/${pc.uid}/edit`} className="btn btn-link"><Icon.Pencil className="text-danger" size="1.2rem" /></Link></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
