import { useState } from 'react';
import { Dropdown } from 'reactstrap';
import styles from './HoverDropdown.module.scss';

export function HoverDropdown(props) {
  const {
    defaultOpen,
    ...toPassProps
  } = props;

  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <Dropdown
      className={`${isOpen ? styles.highlight : ''} ${styles.dropNoSpace}` }
      {...toPassProps}
      isOpen={isOpen}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      toggle={() => setIsOpen(!isOpen)}
    />
  );
}