import { Container } from 'reactstrap';
import { Outlet } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import Sidebar from '../Admin/Sidebar';

import NavMenu from '../Nav/NavMenu';

export function LayoutAdmin(props) {   
    return (
      <div>
        <NavMenu />
        <Container>
          <Row>
            <Col xs={2} id="sidebar-wrapper">
              <Sidebar />
            </Col>
            <Col xs={10} id="page-content-wrapper">
              <Outlet />
            </Col>
          </Row>

        </Container>

      </div>
    );  
}
