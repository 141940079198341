import React, { useState, useEffect } from "react";

import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import api from "../../services/api";

import { Form, Button, Alert } from "react-bootstrap"
import { Formik } from "formik";
import * as Yup from "yup";

import Loading from '../Shared/Loading'

import styles from './Auth.module.scss';


const SetNewPassword = ({isCreateNew}) => {
  const { t } = useTranslation();
  
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialFailed, setInitialFailed] = useState(false);
  const [tncAccepted, setTncAccepted] = useState(false);

  let { token } = useParams();

  const handleLogin = async (data, form) =>  {
    //console.log(data); console.log(form);
    
    setMessage("");
    setLoading(true);

    api.post("/auth/set-password", {
      token: token,
      password: data.password,
      acceptTerms: data.acceptTerms
    },
      { formik: form, setErrorMessage: setMessage }
    )
      .then(response => {
        setStatus(response.data.success ? 2 : 1);
      })
      .catch((error) => { setLoading(false); }); 
  };

  useEffect(() => {  
    function checkToken() {
      //CurrentUserService.setCurrentUser(userUid);
      api
        .post(`/auth/check-reset-token`, { token : token })
        .then((response) => {          
          setInitialLoading(false);          
          if (!response.data.success)
          {
            setInitialFailed(true)
            setStatus(-1);
          };
          setTncAccepted(response.data.tncAccepted);
        });
    };

    checkToken();   

  }, [token]);

  if (initialLoading)
    return <Loading />;
  

  return (
    <>
      {initialFailed && (
        <Alert key="warning" variant="warning">
          <Trans i18nKey="setPassword.tokenInvalid">
            The password reset link you used is expired.Please request for a new reset password link <Alert.Link href="/forgot-password">here</Alert.Link>.
          </Trans>
        </Alert>
      )}

      {status === 2 && (
        <Alert key="success" variant="success">
          <Trans i18nKey="setPassword.successMessage">
            The password was reset successfully.Please log in <Alert.Link href="/login">here</Alert.Link>.
          </Trans>
        </Alert>
      )}

      {status === 1 && (
        <Alert key="warning" variant="warning">
          <Trans i18nKey="setPassword.notSuccessMessage">
            Couldn't reset your password. Please request a new link to reset your password <Alert.Link href="/forgot-password">here</Alert.Link>.
          </Trans>
        </Alert>
      )}


      {status === 0 && (
        <>
          <h1 className={`mb-3 text-center ${styles.header}`}>{isCreateNew ? t('createPassword.header') : t('setPassword.header')}</h1>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
              acceptTerms: tncAccepted
            }}
            validationSchema={Yup.object({
              password: Yup.string()               
                .required(t("validation.required")),
              confirmPassword: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: () => Yup.string().oneOf(
                  [Yup.ref("password")],
                  t('setPassword.doNotMatch')
                )
              }),
              acceptTerms: Yup.bool().oneOf([true], 'You must accept Terms & Conditions and Privacy Policy')
            })}
            onSubmit={handleLogin}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>

                <Form.Group controlId="validationFormik01" className={`form-floating ${styles.inputWrapper}`}>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                    className={styles.input}
                    autoComplete="new-password"
                  />
                  <Form.Label>{t('setPassword.password')}</Form.Label>
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.password}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationFormik02" className={`form-floating ${styles.inputWrapper}`}>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    placeholder="Password"
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                    className={styles.input}
                    autoComplete="new-password"
                  />
                  <Form.Label>{t('setPassword.confirmPassword')}</Form.Label>
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.confirmPassword}</Form.Control.Feedback>
                </Form.Group>
                {!tncAccepted &&
                  <Form.Group controlid="validationFormik05" className={`form-floating ${styles.inputWrapper} ${styles.check}`}>
                    <Form.Check
                      type='checkbox'
                      name="acceptTerms"
                      id="acceptTerms"
                    >
                      <Form.Check.Input type="checkbox" onChange={handleChange} isInvalid={touched.acceptTerms && !!errors.acceptTerms} />
                      <Form.Check.Label>I accept <a href="/tnc" target="_blank">Terms & Conditions</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a></Form.Check.Label>
                      <Form.Control.Feedback type="invalid" className="mb-4">{errors.acceptTerms}</Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                }

                <Button className={`w-100 btn-lg ${styles.submitButton}`} type="submit" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span> {t('setPassword.set')}</span>
                </Button>
                {message && (
                  <Alert key="danger" variant="danger" className="mt-3">
                    {message}
                  </Alert>
                )}
              </Form>
            )}
          </Formik>

          <p className="mt-3 text-center">
            <Trans i18nKey="forgotPassword.goToLogin">
              or <a href="/login">go back</a> to the login page
            </Trans>
          </p>
        </>)}

    </>    
  );
}

export { SetNewPassword };