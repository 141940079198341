import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import * as Yup from 'yup';
import { Formik, Field, Form, FieldArray } from 'formik';
import Box from '../Form/Box'
import TextFieldHalf from '../Form/TextFieldHalf'
import SelectFieldBare from '../Form/SelectFieldBare'


export default function User() {  

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { userId } = useParams();
  let userUrl = userId ? `admin/user/${userId}/edit` : `admin/user/add`;
  let navigate = useNavigate();
  const saveButtonText = userId ? `Update user` : `Add a new user`;  
  const [filteredPcs, setFilteredPcs] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const loadUsers = () => {
      api
        .get(userUrl)
        .then((response) => {
          setData(response.data);          
          setFilteredPcs(response.data.allPlaycentres.filter(pc => 
            !response.data.selectedPlaycentres.some(selectedPc => selectedPc.uid === pc.uid)
          ));
          setLoading(false);
        });
    };

    loadUsers();
  }, []);  

  if (loading)
    return <Loading />;

  const getNewPcObject = (uid) => {
    if (filteredPcs.length === 0) return null;
    if (uid === undefined || filteredPcs.find(pc => pc.uid === uid) == null)
      uid = filteredPcs[0].uid;

    let newPc = {
      uid: uid,
      displayName: data.allPlaycentres.find(pc => pc.uid === uid).displayName,
      permissions: { canViewEverything: true, canEditMembers: true, canEditRoster: true }
    };

    setFilteredPcs(filteredPcs.filter(pc => pc.uid !== uid));

    return newPc;
  };

   

  return (
    <>
      <h1>User - {data?.user ? `${data.user.firstName} ${data.user.lastName}` : 'new user'}</h1>

      <Formik
        initialValues={{ data: data }}
        validationSchema={Yup.object().shape({
          data: Yup.object().shape({
            user: Yup.object().shape({
              firstName: Yup.string().required('Required'),
              lastName: Yup.string().required('Required'),
              email: Yup.string().email('Invalid email address').required('Required')
            })            
          })
        })}
        onSubmit={(values, { setSubmitting }) => {
          api.post(userUrl, { user: values.data.user, selectedPlaycentres: values.data.selectedPlaycentres })
            .then(() => navigate(`/admin/users`))
            .catch((error) => {
              console.log(error); setErrorMessage(error.response.statusText);
          });
          setTimeout(() => {
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >{(props) =>
        <Form>
          <div className="accordion">
            <Box header="Personal Details">
              <div className="row">
                <TextFieldHalf title="First Name" objectPath="data.user.firstName" id="firstName" />
                  <TextFieldHalf title="Last Name" objectPath="data.user.lastName" id="lastName" />
              </div>
              <div className="row">
                  <TextFieldHalf title="Email" objectPath="data.user.email" id="email" inputType="email" />
                  <TextFieldHalf title="Phone number" objectPath="data.user.phoneNumber" id="phone" />
              </div>
            </Box>          

            <Box header="Selected playcentres">
                <div className="row">
                  <div className="col fw-bold">Playcentre name</div>
                  <div className="col fw-bold">Read access</div>
                  <div className="col fw-bold">Can edit members</div>
                  <div className="col fw-bold">Can edit roster</div>
                  <div className="col"></div>
                </div>
                <FieldArray
                  name="data.selectedPlaycentres"
                  render={arraySelectedPcs => (
                    <>
                      <div>
                        {props.values.data.selectedPlaycentres.map((pc, index) => (
                          <div key={index}>
                            <div className="row pb-3">
                              <div className="col">
                                <Field name={`data.selectedPlaycentres[${index}].displayName`} className="form-control" disabled></Field>
                              </div>                              
                              <div className="col">
                                <label>
                                  <Field type="checkbox" name={`data.selectedPlaycentres[${index}].permissions.canViewEverything`} disabled/>                                   
                                </label>
                              </div>
                              <div className="col">
                                <label>
                                  <Field type="checkbox" name={`data.selectedPlaycentres[${index}].permissions.canEditMembers`} />
                                </label>
                              </div>
                              <div className="col">
                                <label>
                                  <Field type="checkbox" name={`data.selectedPlaycentres[${index}].permissions.canEditRoster`} />
                                </label>
                              </div>
                             
                              <div className="col">
                                <button type="button" className="btn btn-warning" onClick={() => { var pc = arraySelectedPcs.pop(index); console.log(pc); filteredPcs.push({ uid: pc.uid, displayName: pc.displayName }); }}> Delete </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="row">
                        <div className="col">                       
                          <SelectFieldBare id="pc_list" objectPath={`data.selectedNewPc`} options={filteredPcs} />
                        </div> 
                        <div className="col">
                          <button
                            type="button" className="btn btn-success"
                            onClick={() => { console.log(props); arraySelectedPcs.push(getNewPcObject(props.values.data.selectedNewPc)) }}
                            disabled={filteredPcs.length === 0}
                          > Add permissions </button>
                        </div>
                        <div className="col"></div> 
                        <div className="col"></div> 
                        <div className="col"></div> 
                      </div>
                    </>
                  )}
                />
            </Box>
          </div>

            <div className="d-grid gap-2 col-6 mx-auto mt-5 mb-5 pb-5">
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <button className="btn btn-primary fw-bold" type="submit">{saveButtonText}</button>
          </div>
          <div className="pt-3"></div>
        </Form>
        }
      </Formik>

    </>
  );
}
