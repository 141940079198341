import { Field } from 'formik';


const SelectFieldBare = ({ id, objectPath, options, defaultOption }) => {
  return (
    <Field name={objectPath} id={id} as="select" className="form-control form-select">
      {defaultOption && <option value="00000000-0000-0000-0000-000000000000">{defaultOption}</option>}
      {options.map((option) => <option key={option.uid} value={option.uid}>{option.displayName}</option>)}
    </Field>
  )
}
export default SelectFieldBare;