import api from "./api";
import TokenService from "./token.service";
class AuthService {
  login(username, password) {
    return api
      .post("/auth/signin", {
        username,
        password
      })
      .then(response => {        
        if (response.data.jwtToken) {
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }
  logout() {
    return api.post("/auth/signout").then(
      response => {
       TokenService.removeUser();
        window.location.reload();
      }).catch((error) => {
        TokenService.removeUser();
        window.location.reload();
      });
  }

  register(firstName, lastName, email, password, phoneNumber, description) {
    return api.post("/auth/signup", {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      description
    });
  }

  getLoggedInUser() {
    return TokenService.getUser();
  }

  ignoreLinks = ['/login', '/register', '/forgot-password', '/privacy-policy', '/tnc' ]

  shouldRedirectToLogin() {
    const currentUser = this.getLoggedInUser();
    return (currentUser === null && !this.ignoreLinks.includes(window.location.pathname) && !window.location.pathname.includes('/set-password/') && !window.location.pathname.includes('/create-password/'));
  }

  shouldShowNavBars() {
    const currentUser = this.getLoggedInUser();
    return (currentUser !== null && !this.ignoreLinks.includes(window.location.pathname) && !window.location.pathname.includes('/set-password/') && !window.location.pathname.includes('/create-password/'));
  }

}
export default new AuthService();