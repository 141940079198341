import React, { useEffect, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { LayoutAuth } from './components/Layouts/LayoutAuth';
import { LayoutStatic } from './components/Layouts/LayoutStatic';
import { LayoutAdmin } from './components/Layouts/LayoutAdmin';

import './custom.css';
import AuthService from "./services/auth.service";
import { useNavigate } from "react-router-dom";

import { Login } from './components/Auth/Login';
import { Register } from './components/Auth/Register';
import { ForgotPasswordRequest } from './components/Auth/ForgotPasswordRequest'
import { SetNewPassword } from './components/Auth/SetNewPassword'
import { WaitingPlaycentreConfirmation } from './components/Auth/WaitingPlaycentreConfirmation'

import { Privacy } from './components/Static/Privacy';
import { TnC } from './components/Static/TnC';

import Users from './components/Admin/Users';
import User from './components/Admin/User';
import Playcentres from './components/Admin/Playcentres';
import Playcentre from './components/Admin/Playcentre';

import Terms from './components/Admin/Terms';

function App(props) {
  let navigate = useNavigate();


  useEffect(() => {
    if (AuthService.shouldRedirectToLogin()) {
      navigate("/login");
    }
  });


  return (
    <Suspense fallback="loading...">
      <Routes>
        <Route element={<LayoutAuth />}>
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/forgot-password' element={<ForgotPasswordRequest />} />
          <Route exact path='/set-password/:token' element={<SetNewPassword isCreateNew={false} />} />
          <Route exact path='/create-password/:token' element={<SetNewPassword isCreateNew={true} />} />
          <Route exact path='/waiting' element={<WaitingPlaycentreConfirmation />} />
        </Route>

        <Route element={<LayoutStatic />}>        
          <Route exact path='/privacy-policy' element={<Privacy />} />
          <Route exact path='/tnc' element={<TnC />} />
        </Route>

        <Route element={<LayoutAdmin />}>
          <Route exact path='/admin/users' element={<Users />} />
          <Route exact path='/admin/user/add' element={<User />} />
          <Route exact path='/admin/user/:userId/edit' element={<User />} />

          <Route exact path='/admin/playcentres' element={<Playcentres />} />
          <Route exact path='/admin/playcentre/add' element={<Playcentre />} />
          <Route exact path='/admin/playcentre/:playcentreId/edit' element={<Playcentre />} />

          <Route exact path='/admin/terms' element={<Terms />} />
        </Route>

        <Route element={<Layout />}>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} element={element} exact {...rest} />;
          })}
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;