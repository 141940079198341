import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import * as Icon from 'react-bootstrap-icons';

export default function Users() {  

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const loadUsers = () => {
      api
        .get(`admin/users`)
        .then((response) => {
          setUsers(response.data.users);
          setLoading(false);
        });
    };

    loadUsers();
  }, []);

  const sendInviteToUser = (user) => {
    const updatedUsers = users.map(u =>
      u.uid === user.uid ? { ...u, lastInviteSentDays: 0, justSent: true, sending: true } : u
    );
    setUsers(updatedUsers);    

    api
      .post(`admin/user/${user.uid}/send_invite`)
      .then((response) => {
        const updatedUsers = users.map(u =>
          u.uid === user.uid ? { ...u, lastInviteSentDays: 0, justSent: true, sending: false } : u
        );
        setUsers(updatedUsers);    
      });
  };

  const getButtonLabel = (justSent, sending) => {
    if (sending)
      return 'Sending...';
    if (justSent)
      return 'Just sent'
    return 'Send invite';
  };

  if (loading)
    return <Loading />;

  return (
    <>
      <h1>User login list</h1>
      <div className="text-end mb-3">
        <Link to="/admin/user/add" className="btn btn-primary">Create a new user</Link>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First&nbsp;name</th>
            <th scope="col">Last&nbsp;name</th>
            <th scope="col">Email</th>
            <th scope="col">Invitation</th>
            <th scope="col">Playcentres</th>            
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.uid}>
              <th scope="row">{index + 1}</th>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>
                {user.showInviteButton &&
                  <>
                  <button className="btn btn-primary" onClick={() => sendInviteToUser(user)} disabled={user.justSent ?? false}>{getButtonLabel(user.justSent, user.sending)}</button>
                    {user.lastInviteSentDays !== undefined && <p>Last: {user.lastInviteSentDays} days ago</p>}
                  </>}
              </td>
              <td>{user.isAdmin ? 'All - ADMIN' : user.playcentres.map((pc) => (<p key={pc.uid}>{pc.displayName}</p>))}</td>
              <td><Link to={`/admin/user/${user.uid}/edit`} className="btn btn-link"><Icon.Pencil className="text-danger" size="1.2rem" /></Link></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
