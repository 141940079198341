import { Field, ErrorMessage } from 'formik';


const TextFieldHalf = ({ id, title, objectPath, inputType }) => {
  return (
    <>
      <div className="form-group pb-3 col-sm-6">
        <label className="col-sm-12 col-form-label fw-bold" htmlFor={id}>{title}</label>
        <div className="col-sm-12">
          <Field name={objectPath} id={id} type={inputType ?? 'text'} className="form-control" />
          <ErrorMessage name={objectPath}>{msg => <div className="alert alert-danger">{msg}</div>}</ErrorMessage>
        </div>
      </div>
    </>
  )
};

export default TextFieldHalf;